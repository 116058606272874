$(document).ready(function(){
  $(".openClass").click(function(){
      $box = $(".products__topul, .brand__topul, .markets__topul");
      minimumHeight = 0;

      // get current height
      currentHeight = $box.innerHeight();

      // get height with auto applied
      autoHeight = $box.css('height', 'auto').innerHeight();

      // reset height and revert to original if current and auto are equal
      $box.css('height', currentHeight).animate({
          height: (currentHeight == autoHeight ? minimumHeight : autoHeight)
      })
  });
  // $(".openClass").click(function(){
  //     $box = $(".markets__topul");
  //     minimumHeight = 0;
  //
  //     // get current height
  //     currentHeight = $box.innerHeight();
  //
  //     // get height with auto applied
  //     autoHeight = $box.css('height', 'auto').innerHeight();
  //
  //     // reset height and revert to original if current and auto are equal
  //     $box.css('height', currentHeight).animate({
  //         height: (currentHeight == autoHeight ? minimumHeight : autoHeight)
  //     })
  // });
  // function tabMenuMobileCheck(){
  //   var windowswidth = $(window).width();
  //
  //   if(windowswidth < 1180){
  //     setTimeout(function(){
  //       $(".products__topList").css({
  //           display: 'block',
  //           height: '0'
  //
  //       });
  //     }, 100);
  //   }else{
  //     $(".products__topList").css({
  //         display: 'block',
  //         height: 'auto'
  //     });
  //   }
  // }
  // window.onload=function(){
  //   tabMenuMobileCheck();
  // };
  //
  // $(window).on('resize', function (){
  //   tabMenuMobileCheck();
  // });

});
