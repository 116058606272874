  const counterAnim = (qSelector, start = 0, end, duration = 1000) => {
  const target = document.querySelector(qSelector);
  let startTimestamp = null;
  const step = (timestamp) => {
   if (!startTimestamp) startTimestamp = timestamp;
   const progress = Math.min((timestamp - startTimestamp) / duration, 1);
   target.innerText = Math.floor(progress * (end - start) + start);
   if (progress < 1) {
    window.requestAnimationFrame(step);
    }
   };
   window.requestAnimationFrame(step);
  };
   //#endregion - end of - number counter animation
   var count1 = $('#count1').data('number');
   var count2 = $('#count2').data('number');
   var count3 = $('#count3').data('number');
   var count4 = $('#count4').data('number');

   document.addEventListener("DOMContentLoaded", () => {
     if(count1){//如果物件存在
       counterAnim("#count1", count1/3, count1, 1500);
       counterAnim("#count2", count2/3, count2, 1500);
       counterAnim("#count3", count3/3, count3, 5000);
       counterAnim("#count4", count4/3, count4, 1500);
     }
   });

// (id="count"+[i+1] data-number=count[i].number)
